



































































import { Component, Vue } from 'vue-property-decorator';

import InputField from '@/shared/resources/components/forms/fields/InputField.vue';
import Divider from '@/shared/resources/components/Divider.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import Card from '@/shared/resources/components/cards/Card.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import Alert from '@/shared/resources/components/Alert.vue';
import AuthLayoutLogo from '@/app/layouts/auth/AuthLayoutLogo.vue';
import { Validations } from 'vuelidate-property-decorators';
import { authPasswordResetValidation } from '@/app/modules/auth/config/authValidation';
import IconMessage from '@/shared/resources/components/IconMessage.vue';
import Auth from '@/app/lib/auth/Auth';

@Component({
  components: {
    IconMessage,
    AuthLayoutLogo,
    Alert,
    GridCol,
    Card,
    Button,
    Divider,
    InputField,
  },
})
export default class AuthRequestPasswordReset extends Vue {
  /**
   * Validations
   */
  @Validations()
  private get registerRules() {
    return authPasswordResetValidation();
  }

  /**
   * Data
   */
  private email: string | null = null;
  private loading: boolean = false;
  private success: boolean = false;
  private errorMessage: string | null = null;

  /**
   * Display getters
   */
  private get displaySuccess(): boolean {
    return this.success;
  }

  private get displayForm(): boolean {
    return !this.success;
  }

  private get displayError(): boolean {
    return !!this.errorMessage;
  }

  /**
   * Methods
   */
  private async resetPassword() {
    this.loading = true;
    this.errorMessage = null;

    try {
      await Auth.requestPasswordReset(this.email!);
      this.success = true;
    } catch (ex) {
      if (ex.isExpectationFailed() || ex.isUnprocessableEntity()) {
        this.errorMessage = 'Niepoprawny adres e-mail.';
      } else {
        this.errorMessage = 'Wystąpił nieoczekiwany błąd. Prosimy o&nbsp;kontakt.';
      }
    }

    this.loading = false;
  }

  /**
   * Handlers
   */
  private onFormSubmit(e: Event) {
    e.preventDefault();
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.resetPassword();
    }
  }
}
